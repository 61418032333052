<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <v-btn
      name="Retour"
      class="ma-6"
      text
      icon
      color="primary"
      @click="backTo()"
    >
      <v-icon size="60px">mdi-arrow-left-circle-outline</v-icon>
    </v-btn>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <Order />
      </v-col>
      <v-col
        cols="12"
        md="3"
        align-center
      >
        <Resume />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Resume from "./../components/OrderVueComponent/Resume.vue";
import Order from "./../components/OrderVueComponent/Order.vue";
export default {
  name: "OrderVue",

  components: {
    Order,
    Resume,
  },
  methods: {
    backTo() {
      this.$router.push({ path: `/admin/etablissement` }).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
};
</script>
