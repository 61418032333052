<template>
  <v-card
      class="ma-4 pa-4"
      min-height="50%"
  >
    <v-toolbar-title
        class="mx-4 mb-4"
        v-if="!$vuetify.breakpoint.smAndUp"
    >Commande
    </v-toolbar-title>
    <v-card-title v-if="!$vuetify.breakpoint.smAndUp">
      <p class="text-break ma-0">
        <span class="primary--text">Etablissement: </span>
        <span class="secondary--text">{{ etablissementInfo.id }}</span>
        <span class="primary--text"> {{ etablissementInfo.nom }}</span>
      </p>
    </v-card-title>
    <template>
      <v-toolbar flat>
        <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp">Commande</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
            v-if="$vuetify.breakpoint.smAndUp"
        ></v-divider>
        <v-card-title
            class="text-break"
            v-if="$vuetify.breakpoint.smAndUp"
        >
          <p class="text-break ma-0">
            <span class="primary--text">Etablissement: </span>
            <span class="secondary--text">{{ etablissementInfo.id }}</span>
            <span class="primary--text"> {{ etablissementInfo.nom }}</span>
          </p>
        </v-card-title>
        <v-spacer/>
        <BooksTab
            action="Ajouter"
            class="ma-auto"
            :userBooks="additionalsBooks"
            @delete="catchDeleteEmit"
            @add="catchAddEmit"
            editeur=""
        />
      </v-toolbar>
    </template>
    <div class="d-flex justify-center ma-4">
      <v-progress-circular
          v-if="loading"
          :size="50"
          color="primary"
          indeterminate
          justify-center
      ></v-progress-circular>
    </div>
    <div v-if="divaltoDown">
      <h3 class="text-center">Le service Divalto ne répond pas.</h3>
    </div>
    <div v-else-if="treeviewdata.length === 0 && !loading">
      <h3 class="text-center">L'établissement n'a pas de devis numérique dans Divalto.</h3>
    </div>
    <v-treeview
        :items="treeviewdata"
        hoverable
        class="ma-4"
        v-else-if="!loading"
    >
      <template v-slot:prepend="{ item }">
        <v-icon
            color="primary"
            v-if="item.type === 'lot'"
        >
          mdi-package-variant
        </v-icon>
        <v-img
            height="100px"
            width="80px"
            aspect-ratio="3/4"
            v-if="item.type === 'livre' && item.imgCouverture && $vuetify.breakpoint.smAndUp"
            :src="item.imgCouverture"
            class="ma-2"
        ></v-img>
        <v-icon
            color="primary"
            v-if="item.type === 'livre' && !item.imgCouverture "
        > mdi-book
        </v-icon>
        <v-icon
            color="primary"
            v-if="item.type === 'devis'"
        >
          mdi-school
        </v-icon>
      </template>
      <template v-slot:label="{ item }">
        <span v-if="item.type ===`livre`">

          <span class="primary--text">Titre :
            <span class="secondary--text">
              {{ item.name }}
            </span>
          </span>
          <br>
          <div class="d-flex justify-start">
            <span class="primary--text caption">Ref. Divalto : {{ item.refOffreDivalto }}</span>
            <Tooltip v-if="!item.canOrder" textToolTip="Attention, cette référence est périmée et ne fait plus partie du retour api lors de l'import catalogue editeur">
                <v-icon color="warning">
                  mdi-alert
                </v-icon>
              </Tooltip>
          </div>
          <span
              class="primary--text caption"
              v-if="item.quantity !== -1"
          >Editeur : {{ item.editeur }}</span><br v-if="item.quantity !== -1">
          <span class="primary--text caption">Matière : {{ item.matiere }}</span><br>
          <span
              class="primary--text caption"
              v-if="item.quantity !== -1"
          >Prix TTC: {{ item.prix }} €</span><br v-if="item.quantity !== -1">
          <v-divider>
          </v-divider>
        </span>
        <span
            v-else
            class="primary--text"
        >{{ item.name }}</span>
        <div v-if="!$vuetify.breakpoint.smAndUp">
          <v-btn
              name="diminuer"
              small
              v-if="item.type==='lot'"
              @click="decreaseLot(item);"
              outlined
              color="secondary"
              class="ma-1"
          >-1
          </v-btn>
          <v-btn
              name="diminuer par 10"
              small
              v-if="item.type==='lot'"
              @click="decreaseLot10(item);"
              color="secondary"
              outlined
              class="ma-1"
          >-10
          </v-btn>
          <v-btn
              name="augmeter"
              v-if="item.type==='lot'"
              @click="increaseLot(item);"
              small
              color="green"
              outlined
              class="ma-1"
          >+1
          </v-btn>
          <v-btn
              name="augmeter par 10"
              small
              v-if="item.type==='lot'"
              @click="increaseLot10(item);"
              outlined
              color="green"
              class="ma-1"
          >+10
          </v-btn>
        </div>
      </template>
      <template v-slot:append="{ item }">
        <v-row class="my-1">
          <v-text-field
              v-if="item.type==='livre' && item.quantity !== -1"
              v-model.number="item.quantity"
              type="number"
              style="width: 100px"
              min="0"
              item-key="id"
              class="pl-4"
          ></v-text-field>
          <div v-if="$vuetify.breakpoint.smAndUp">
            <v-btn
                name="diminuer"
                small
                v-if="item.type==='lot'"
                @click="decreaseLot(item);"
                outlined
                color="secondary"
                class="ma-1"
            >-1
            </v-btn>
            <v-btn
                name="diminuer par 10"
                small
                v-if="item.type==='lot'"
                @click="decreaseLot10(item);"
                color="secondary"
                outlined
                class="ma-1"
            >-10
            </v-btn>
            <v-btn
                name="augmeter"
                v-if="item.type==='lot'"
                @click="increaseLot(item);"
                small
                color="green"
                outlined
                class="ma-1"
            >+1
            </v-btn>
            <v-btn
                name="augmeter par 10"
                small
                v-if="item.type==='lot'"
                @click="increaseLot10(item);"
                outlined
                color="green"
                class="ma-1"
            >+10
            </v-btn>
          </div>

        </v-row>
      </template>
    </v-treeview>

  </v-card>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import BooksTab from "../Utility/BooksTab.vue";
import AdminService from "./../../services/AdminService";
import Tooltip from "../Utility/Tooltip.vue";

export default {
  components: {
    BooksTab,
    Tooltip
  
  },
  computed: {
    ...mapState("order", ["additionalsBooks"]),
    ...mapGetters("order", [
      "treeviewdata",
      "panier",
      "classes",
      "aditionnalClasse",
    ]),
  },
  data: () => ({
    loading: false,
    divaltoDown: false,
    Ishovered: true,
    boutonMove: "pr-4",
    etablissementInfo: [],
  }),

  async mounted() {
    this.getDevis();
    this.getEtab();
  },
  methods: {
    getEtab() {
      AdminService.getEtablissementByID(this.$route.params.id).then(
          ({data}) => {
            this.etablissementInfo = data;
          }
      );
    },
    getDevis() {
      if (!this.$store.state.order.isLoad) {
        this.loading = true;
        this.$store
            .dispatch("order/getDevis", this.$route.params.id)
            .then(() => {
              this.divaltoDown = false;
            })
            .catch((error) => {
              if (error.response.status === 503) {
                // Divalto est down ?
                this.divaltoDown = true;
                this.$store.dispatch("setSnackbar", {
                  color: "error",
                  text: "Une erreur est survenue avec Divalto, veuillez réessayer dans un moment.",
                });
              }
            })
            .finally(() => (this.loading = false));
      }
    },
    hovered() {
      this.Ishovered = false;
      this.boutonMove = "pr-2";
    },
    notHovered() {
      this.Ishovered = true;
      this.boutonMove = "pr-4";
    },
    catchDeleteEmit(payload) {
      this.$store.dispatch("order/DeleteAdditionalsBooks", payload);
    },
    catchAddEmit(payload) {
      this.$store.dispatch("order/AddAdditionalsBooks", payload);
    },
    increaseLot(item) {
      this.$store.dispatch("order/increase", item);
    },
    decreaseLot(item) {
      this.$store.dispatch("order/decrease", item);
    },
    increaseLot10(item) {
      this.$store.dispatch("order/increaseBy10", item);
    },
    decreaseLot10(item) {
      this.$store.dispatch("order/decreaseBy10", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.AccessButton {
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0;
}

.v-card__title {
  white-space: pre-line;
}
</style>
