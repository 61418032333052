<template>
  <v-card
      class="ma-auto d-flex flex-column pa-4 rounded-xl mr-md-4"
      min-height="200px"
      max-width="400px"
  >
    <div class="text-subtitle-1 primary--text text-center text-uppercase">
      <v-icon class="primary--text">mdi-cart-minus</v-icon>
      Panier
    </div>

    <v-divider class="mx-4 my-4"></v-divider>
    <v-row
        class="mx-1"
        v-for="classe in panier"
        :key="classe.codeClasse"
    >
      <v-col cols="12">{{ classe.codeClasse }}</v-col>
      <v-col cols="12">
        <v-row
            class="mx-2"
            v-for="item in classe.bibliotheques"
            :key="item.info1"
        >
          <v-col
              cols="2"
              md="3"
          >{{ item.quantity }}
          </v-col>
          <v-spacer/>
          <v-col
              cols="10"
              md="9"
              class=""
          > {{ item.name }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="mt-auto">
      <v-row class="mx-2 mt-3">
        <v-col> Total</v-col>
        <v-spacer/>
        <v-col> {{ totaux.totalQuantity }}</v-col>
      </v-row>
      <v-divider class="mx-4 mb-4"></v-divider>
      <v-row
          :justify="$vuetify.breakpoint.xs ? 'center' : 'end'"
          class="mt-5 mb-1"
          :class="$vuetify.breakpoint.xs ? 'pr-0' : 'pr-3'"
      >
        <NextButton
            width="170"
            color="primary"
            text="Commander"
            @clicked="clic=true,goToValidate()"
            :disable="!totaux.totalQuantity||clic"
        />
      </v-row>
    </div>
  </v-card>

</template>
<script>
import {mapGetters} from "vuex";
import NextButton from "../Utility/NextButton.vue";

export default {
  components: {
    NextButton,
  },
  data() {
    return {
      clic: false,
    };
  },
  computed: {
    ...mapGetters("order", ["totaux", "panier", "panierParEditeur"]),
  },
  methods: {
    goToValidate() {
      let EtabId = this.$route.params.id;
      this.$router.push({path: `/admin/order/${EtabId}/summary`}).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
};
</script>
